*{
	box-sizing: border-box;
	font-family: OpenSans, serif; 
}


html, body{
		background-color: black;
		margin: 0;
		padding: 0;
}

.main-nav ul {
		margin: 0;
}
.main-nav li {
	display: inline;
	padding: 0 5%;
}
.main-nav {
	text-align: center;
	font-size: 1.1em;
	font-weight: lighter;
	border-bottom: 1px solid black;
}

.nav {
	display: inline-block;
	padding: .5em;
	color: white;
	text-decoration: none;
}
.nav:hover{
	background-color: white);
}
.main-header {
	background-image:url("../public/Artwork/headeroption2.jpg");
	background-size: cover;
	padding-bottom: 20px;
	padding-top: 20px;
}
.full-name {
		text-align: center;
		margin: 0;
		font-size: 3.5em;
		color: black;
		text-shadow: -1px -1px 0 	#FFFFFF,
									1px -1px 0 	#FFFFFF,
									1px 1px 0 	#FFFFFF,
									1px 1px 0 	#FFFFFF;
}
.Photography-section{
	text-align: center; 
	color: white; 
	display:inline; 
	display: block;
	margin-left: auto; 
	margin-right: auto; 
}
.section-header{
	text-align: center;
	font-weight: normal;
}
.content-section1{
	margin: 1em;
	padding: 0 5%;
}
	.project1{
		height: 208px;
		width: 310px;
		padding: 15px; 
		}
	.disruption{
		height: 310px;
		width: 208px; 
		padding:15px;
	}
	.work_in_progress{
		padding: 10px;
		color: white; 
	}
		
.content-section2{
	text-align: center;
	color: white; 
	display: inline; 
	margin: 1em;
}
	.project2{
		height:208px; 
		width: 310px; 
		padding: 15px;
	}
.container {
	max-width: 900px;
	margin: 0 auto;
	padding: 0 1.5em;
}
.artist-statement{
	text-align: center; 
	color: white;
	margin-left: 50px;
	margin-right: 50px;
}
